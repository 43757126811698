import React, { useState, useEffect } from "react";
import RestaurantDataService from "../services/restaurant";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "@mui/material/Card";
import LikeButton from "./like-button";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import CardExpanded from "./expandedCard";
import { Rating } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import { IconButton } from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PlayerProfile from "./playerProfile";
import Footer from "./footer";
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import Review from "./images/review.jpeg"


const PlayersReviews = (props) => {

  const [reviews, setReviews] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const { user, isLoading } = useAuth0();
  const [openDialogue, setOpenDialogue] = useState(false);

  const handleClickOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  var res;

  const [alignment, setAlignment] = useState('latest');

  const handleChangeAlignment = (event, newAlignment) => {
    if (newAlignment !== null && newAlignment !== "following") {
        setAlignment(newAlignment);
    }
 
    if (newAlignment === "likes"){
        find("likes")
    }else if (newAlignment === "latest"){
        find("date")
    }else if (newAlignment === "following"){
        if (user){
            setAlignment(newAlignment)
            getFollow(user.sub)
        }else{
            handleClickOpenDialogue();
        }
    }
  };

  useEffect(() => {
    find("date");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getFollow = (user_id) => {
    RestaurantDataService.getFollow(user_id)
      .then((response) => {
        if (response.data.follow.length > 0) {
            var follow_ids = Object.keys(response.data.follow[0].following).join();
            find("follow",follow_ids);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProfile = (ids) => {
    let queryString = "";
    for (let i = 0; i < ids.length; i++) {
      queryString += ids[i].user_id + ",";
    }

    RestaurantDataService.getProfile(queryString)
      .then((response) => {
        let info = response.data.userInfo;
        if (!info){
            info = {};
        }

        for (let i = 0; i < ids.length; i++) {
          if (!(ids[i].user_id in info)) {
            info[ids[i].user_id] = [
              ids[i].name,
              ids[i].user_pic,
              "",
              "",
              false,
            ];
          }
        }
        setUserInfo(info);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const find = (by,follow_ids="") => {
    RestaurantDataService.getReviews(by,follow_ids)
      .then((response) => {
        setReviews(response.data.reviews);
        res = response.data;
        getProfile(res.uniqueIDs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const useStyles = makeStyles((theme) => ({
    root: (props) => {
      return {
        "&.Mui-selected, &.Mui-selected:hover": {
          color: "white",
          backgroundColor: '#2196f3'
        },
        color: "black",
      };
    }
  }));
  
  function MyToggleButton(props) {
    const { color, ...other } = props;
    const classes = useStyles({ color });
    return <ToggleButton classes={classes} {...other} />;
  }

  return (
    <div>
      <img src={Review} alt="poster" className="tga"></img>
      <Card sx={{ boxShadow: "0"}}>
      <div
          style={{ display:"flex", justifyContent: "center", alignItems: "center" ,marginTop:10}}
        >
      <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleChangeAlignment}
      sx={{boxShadow:2, borderRadius:3}}
    >
      <MyToggleButton value="latest" sx={{borderRadius:3,textTransform: "none",fontSize: 16}}>Latest</MyToggleButton>
      <MyToggleButton value="likes" sx={{borderRadius:3,textTransform: "none",fontSize: 16}}>Most Likes</MyToggleButton>
      {!isLoading &&(<MyToggleButton value="following" sx={{borderRadius:3,textTransform: "none",fontSize: 16}}>Following</MyToggleButton>)}
    </ToggleButtonGroup>
    <Dialog
              open={openDialogue}
              onClose={handleCloseDialogue}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
            >
              <DialogTitle>Following</DialogTitle>
              <Box sx={{ height: 50, width: 300 }}>
                <Typography
                  sx={{ marginLeft: 3 }}
                  variant="body3"
                  color="text.secondary"
                >
                  Please sign in.
                </Typography>
              </Box>
              <DialogActions>
                <Button onClick={handleCloseDialogue} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Close</Button>
              </DialogActions>
            </Dialog>
    </div>
        <div
          className="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {reviews.length > 0 ? (
            reviews.map((review, index) => {
              const t = new Date(review.date)
                .toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
                .split(", ");
              const m = t[1].split(/:| /) 
              const time = m[0] + ":" + m[1] + " "+ m[3]+  " • " + t[0];

              const wordLimit = (review.title === "" ? 55 : 45);
              let reviewText = review.text.split(" ").slice(0, wordLimit).join(" ");
              if (reviewText !== review.text) {
                reviewText += " ...";
              }

              return (
                //<Grid item xs={4}>
                <Card
                  key={review._id}
                  sx={{
                    ":hover": { boxShadow: 15 },
                    height: 660,
                    width: 500,
                    marginTop: 2.5,
                    marginBottom: 2.5,
                    marginRight: 2.5,
                    marginLeft: 2.5,
                    padding: 0,
                    borderRadius: 4,
                    boxShadow: 5,
                  }}
                >
                  <CardMedia
                    component="img"
                    height="280"
                    image={review.image}
                  />

                  <Typography
                    gutterBottom
                    variant="h5"
                    sx={{ marginTop: 2, marginLeft: 2 }}
                  >
                    {review.game}
                  </Typography>
                  <div>
                    <Box  sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      <Grid container>
                        <Grid item xs={1.6}>
                          {userInfo[review.user_id] && (
                            <Box
                              component="img"
                              sx={{
                                boxShadow:20,
                                height: 50,
                                width: 50,
                                borderRadius: 50,
                              }}
                              src={userInfo[review.user_id][1]}
                            />
                          )}
                        </Grid>
                        <Grid item xs={4.5}>
                          <Box sx={{ marginTop: 0.5 }}>
                            {userInfo[review.user_id] && (
                              <Typography gutterBottom variant="body3">
                                {userInfo[review.user_id][0]}
                              </Typography>
                            )}
                          </Box>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="text.secondary"
                          >
                            {time}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            sx={{
                              boxShadow: 2,
                              height: 30,
                              backgroundColor: "#2196f3",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderRadius: 3,
                              marginTop: 1,
                            }}
                          >
                            <strong style={{ color: "white", fontSize: 12 }}>
                              {review.time + " hrs"}
                            </strong>
                          </Box>
                        </Grid>
                        <Grid item xs={0.9}></Grid>
                        <Grid item xs={1}>
                          {(userInfo[review.user_id] && !isLoading) && (
                            <PlayerProfile
                              email={review.email}
                              userInfo={userInfo[review.user_id]}
                              id ={review.user_id}
                              login_user={user}
                            />
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            target="_blank"
                            href={"/games/" + review.restaurant_id}
                          >
                            <SportsEsportsIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          {userInfo[review.user_id] && (
                            <CardExpanded
                              id={props.match.params.id}
                              review={review}
                              time={time}
                              userName={userInfo[review.user_id][0]}
                              userPic={userInfo[review.user_id][1]}
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Box sx={{ marginTop: 1 }}>
                        <Grid container>
                          <Grid item xs={10.25}>
                            <Rating
                              name="read-only"
                              value={review.star}
                              readOnly
                            />{" "}
                          </Grid>
                          <Grid item xs={1.75} sx={{marginTop:-1.5}}>                     
                            <LikeButton
                              likesParam={new Set(review.likes)}
                              user={user}
                              review_id={review._id}
                              isLoading={isLoading}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontSize={25}
                        component="div"
                      >
                        {review.title}
                      </Typography>
                      <Typography variant="body1" fontSize={18} component="div">
                        {reviewText}
                      </Typography>
                    </Box>
                  </div>
                </Card> //</Grid>
              );
            })
          ) : (
            <div></div>
          )}
          {reviews.length > 0 && (
            <Divider>
              <CommentIcon />
            </Divider>
          )}
          <Footer />
        </div>
      </Card>
    </div>
  );
};

export default PlayersReviews;
