import React, { useState } from "react";
import { Typography } from "@mui/material";
import { DialogContent } from "@mui/material";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import PlayerProfileM from "./playerProfile_m";
import RestaurantDataService from "../../services/restaurant";
import { DialogTitle } from "@mui/material";
import { DialogActions } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { IconButton } from "@mui/material";

export default function FollowListM({ followed, follow, user, closeParent,getFollow}) {
  const [list, setList] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setList(Object.entries(follow));
    getProfile(Object.keys(follow), follow);
    setOpen(true);
  };

 
  const handleClose = () => {
    getFollow(user.sub);
    setOpen(false);
  };

  const handleCloseAll = () => {
    closeParent();
    setOpen(false);
  };

  const getProfile = (ids, data) => {
    let queryString = ids.join();

    RestaurantDataService.getProfile(queryString)
      .then((response) => {
        let info = response.data.userInfo;
        if (!info) {
          info = {};
        }

        for (let i = 0; i < ids.length; i++) {
          if (!(ids[i] in info)) {
            info[ids[i]] = [
              data[ids[i]].name,
              data[ids[i]].picture,
              "",
              "",
              false,
            ];
          }
        }
        setUserInfo(info);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Button
        disableRipple
        onClick={handleOpen}
        sx={{
          padding: 0,
          "&:hover": {
            backgroundColor: "white",
            color: "#2196f3",
          },
          color: "black",
          textTransform: "none",
          fontWeight: "bold",
          marginLeft: 1,
        }}
      >
        {followed ? "Followers" : "Following"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle>
          <IconButton disableRipple onClick={handleClose}>
            <ArrowBackIosNewIcon
              fontSize="small"
              sx={{ marginRight: 1, marginLeft: -1 }}
            />
          </IconButton>
          {followed ? ("Followers") : ("Following")}
        </DialogTitle>
        <DialogContent>
          <div
            className="scrollable-div"
            style={{ width: "70vw", height: 250, paddingTop: 0,}}
          >
            {list.map((player) => {
              return (
                <Box sx={{  marginTop: 1, marginBottom: 1}} key={player[0]}>
                  {userInfo[player[0]] && (
                    <Grid container>
                      <Grid item xs={2}>
                        <Box
                          component="img"
                          sx={{
                            height: 40,
                            width: 40,
                            borderRadius: 50,
                          }}
                          src={userInfo[player[0]][1]}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography fontSize={14} sx={{ marginTop: 1.5, marginLeft: 1 }}>
                          {userInfo[player[0]][0]}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sx={{marginTop:1}}>
                        <PlayerProfileM
                          email={player[1].email}
                          userInfo={userInfo[player[0]]}
                          id={player[0]}
                          login_user={user}
                          fromProfile={true}
                          closeWindows={handleCloseAll}          
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAll}
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
