import React, { useState, useEffect } from "react";
import RestaurantDataService from "../../services/restaurant";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CardActionArea } from "@mui/material";
import Footer from "./footer_m";
import "./style_m.css";
import { Autocomplete, createFilterOptions } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MenuItem } from "@mui/material";

import Zelda from "./images_m/zelda.png"
import Tlou from "./images_m/tlou.png"
import Rdr2 from "./images_m/rdr2.png"
import EldenRing from "./images_m/eldenring.png"
import Cyberpunk from "./images_m/cyberpunk.png"
import Msfs from "./images_m/msfs.png"

const RestaurantsListM = (props) => {
  const myRef = useRef(null)
  const [restaurants, setRestaurants] = useState([]);
  const [names, setNames] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchGenre, setSearchGenre] = useState("");
  const [genres, setGenres] = useState(["All Genres"]);
  const [page, setPage] = useState(1);
  const [searchPlatform, setSearchPlatform] = useState("");
  const [sortOption, setSortOption] = useState("Default");
  const [platforms, setPlatforms] = useState(["All Platforms"]);
  const [totalResults, setTotalResults] = useState(0);
  const [open, setOpen] = useState(false);
  const sortOptions = [
    "Default",
    "Highest MetaScore",
    "Lowest MetaScore",
    "Newest Games",
    "Oldest Games",
  ];

  useEffect(() => {
    retrieveRestaurants();
    if (localStorage.getItem("names")) {
      setNames(JSON.parse(localStorage.getItem("names")));
    } else {
      retrieveAllNames();
    }
    retrieveGenres();
    retrievePlatforms();
  }, []);

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const retrieveRestaurants = (page = 0) => {
    RestaurantDataService.getAll(page,"mobile")
      .then((response) => {
        setRestaurants(response.data.restaurants);
        setTotalResults(response.data.total_results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveAllNames = () => {
    RestaurantDataService.getAllGameNames()
      .then((response) => {
        setNames(response.data);
        localStorage.setItem("names", JSON.stringify(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event, value) => {
    find(value);
    window.scrollTo(0, myRef.current.offsetTop-55) 
  };

  const handleChangeGenre = (e) => {
    const searchGenre = e.target.value;
    setSearchGenre(searchGenre);
    setPage(1);
    if (
      searchName === "" &&
      (searchGenre === "All Genres" || searchGenre === "") &&
      (searchPlatform === "All Platforms" || searchPlatform === "") &&
      sortOption === "Default"
    ) {
      retrieveRestaurants(0);
    } else {
      let query = [];
      if (searchName !== "") {
        query.push(["name", searchName]);
      }
      if (searchGenre !== "" && searchGenre !== "All Genres") {
        query.push(["genre", searchGenre]);
      }
      if (searchPlatform !== "" && searchPlatform !== "All Platforms") {
        query.push(["platform", searchPlatform]);
      }
      if (sortOption !== "Default") {
        query.push(["sortOption", sortOption]);
      }
      findCombo(query, 0);
    }
  };

  const handleChangeName = (value) => {
    var searchName;
    if (value === null) {
      searchName = "";
      setSearchName("");
    } else {
      searchName = value;
      setSearchName(searchName);
    }
    setPage(1);
    if (
      searchName === "" &&
      (searchGenre === "All Genres" || searchGenre === "") &&
      (searchPlatform === "All Platforms" || searchPlatform === "") &&
      sortOption === "Default"
    ) {
      retrieveRestaurants(0);
    } else {
      let query = [];
      if (searchName !== "") {
        query.push(["name", searchName]);
      }
      if (searchGenre !== "" && searchGenre !== "All Genres") {
        query.push(["genre", searchGenre]);
      }
      if (searchPlatform !== "" && searchPlatform !== "All Platforms") {
        query.push(["platform", searchPlatform]);
      }
      if (sortOption !== "Default") {
        query.push(["sortOption", sortOption]);
      }
      findCombo(query, 0);
    }
  };

  const handleChangePlatform = (e) => {
    const searchPlatform = e.target.value;
    setSearchPlatform(searchPlatform);
    setPage(1);
    if (
      searchName === "" &&
      (searchGenre === "All Genres" || searchGenre === "") &&
      (searchPlatform === "All Platforms" || searchPlatform === "") &&
      sortOption === "Default"
    ) {
      retrieveRestaurants(0);
    } else {
      let query = [];
      if (searchName !== "") {
        query.push(["name", searchName]);
      }
      if (searchGenre !== "" && searchGenre !== "All Genres") {
        query.push(["genre", searchGenre]);
      }
      if (searchPlatform !== "" && searchPlatform !== "All Platforms") {
        query.push(["platform", searchPlatform]);
      }
      if (sortOption !== "Default") {
        query.push(["sortOption", sortOption]);
      }
      findCombo(query, 0);
    }
  };

  const handleChangeSortOption = (e) => {
    const sortOption = e.target.value;
    setSortOption(sortOption);
    setPage(1);
    if (
      searchName === "" &&
      (searchGenre === "All Genres" || searchGenre === "") &&
      (searchPlatform === "All Platforms" || searchPlatform === "") &&
      sortOption === "Default"
    ) {
      retrieveRestaurants(0);
    } else {
      let query = [];
      if (searchName !== "") {
        query.push(["name", searchName]);
      }
      if (searchGenre !== "" && searchGenre !== "All Genres") {
        query.push(["genre", searchGenre]);
      }
      if (searchPlatform !== "" && searchPlatform !== "All Platforms") {
        query.push(["platform", searchPlatform]);
      }
      if (sortOption !== "Default") {
        query.push(["sortOption", sortOption]);
      }
      findCombo(query, 0);
    }
  };

  const retrieveGenres = () => {
    RestaurantDataService.getGenres()
      .then((response) => {
        setGenres(["All Genres"].concat(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrievePlatforms = () => {
    RestaurantDataService.getPlatforms()
      .then((response) => {
        setPlatforms(["All Platforms"].concat(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const find = (value = 1) => {
    setPage(value);
    if (
      searchName === "" &&
      (searchGenre === "All Genres" || searchGenre === "") &&
      (searchPlatform === "All Platforms" || searchPlatform === "") &&
      sortOption === "Default"
    ) {
      retrieveRestaurants(value - 1);
    } else {
      let query = [];
      if (searchName !== "") {
        query.push(["name", searchName]);
      }
      if (searchGenre !== "" && searchGenre !== "All Genres") {
        query.push(["genre", searchGenre]);
      }
      if (searchPlatform !== "" && searchPlatform !== "All Platforms") {
        query.push(["platform", searchPlatform]);
      }
      if (sortOption !== "Default") {
        query.push(["sortOption", sortOption]);
      }
      findCombo(query, value - 1);
    }
  };

  const findCombo = (query, page = 0) => {
    RestaurantDataService.findCombo(query, page,"mobile")
      .then((response) => {
        setRestaurants(response.data.restaurants);
        console.log(response.data.total_results);
        setTotalResults(response.data.total_results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    find();
  };

  const filterOptions = createFilterOptions({
    limit: 50,
  });

  return (
    <div style={{ marginTop: 70 }}>
      <center>
        <Carousel
          autoPlay={true}
          showThumbs={false}
          infiniteLoop={true}
          showStatus={false}
          showArrows={false}
        >
          <a
            href={"/games/62c1a5ebadb63b89a4501b32"}
          >
            <div>
              <img
                src={Zelda}
                alt="slide1"
                style={{
                  width: "92vw",
                  height: 250,
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              />
            </div>
          </a>
          <a
            href={"/games/62c1a5d1d973c06d6df5d014"}
          >
            <div>
              <img
                src={Tlou}
                alt="slide2"
                style={{
                  width: "92vw",
                  height: 250,
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              />
            </div>
          </a>
          <a
            href={"/games/62c1a5869b095ed52d828ea7"}
          >
            <div>
              <img
                src={Rdr2}
                alt="slide3"
                style={{
                  width: "92vw",
                  height: 250,
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              />
            </div>
          </a>
          <a
            href={"/games/62c1a61eec4ffc5611cd2c2d"}
          >
            <div>
              <img src={EldenRing} alt="slide4" style={{
                  width:"92vw",
                  height: 250,
                  objectFit: "cover",
                  borderRadius: 10,
                }}/>
            </div>
          </a>
          <a
            href={"/games/62c1a77af0ab8ad51b1d1986"}
          >
            <div>
              <img
                src={Msfs}
                alt="slide5"
                style={{
                  width: "92vw",
                  height: 250,
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              />
            </div>
          </a>
          <a
            href={"/games/62c1a5869b095ed52d828ec2"}
          >
            <div>
              <img src={Cyberpunk} alt="slide6" style={{
                  width: "92vw",
                  height: 250,
                  objectFit: "cover",
                  borderRadius: 10,
                }}/>
            </div>
          </a>
        </Carousel>
      </center><div ref={myRef}></div>
      <div>
        <div
          className="input-group col-lg-4"
      
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 235,
                height:40,
                borderRadius: 3,
                boxShadow: 2,
              }}
            >
              <IconButton>
                <MenuIcon fontSize="small"/>
              </IconButton>

              <Autocomplete
                open={open}
                style={{ width: 235 }}
                ListboxProps={{
                  sx: { fontSize: 14,maxHeight:250}
                }}
                onChange={(event, value) => {
                  handleChangeName(value);
                }}
                onInputChange={(event, value) => {
                  setSearchName(value);
                  if (value === "") {
                    setOpen(false);
                  }
                }}
                onOpen={() => {
                  if (searchName !== "") {
                    setOpen(true);
                  }
                }}
                onClose={() => setOpen(false)}
                freeSolo
                inputValue={searchName}
                options={names.map((option) => option.name)}
                filterOptions={filterOptions}
                renderInput={(params) => {
                  const { InputLabelProps, InputProps, ...rest } = params;
                  return (
                    <InputBase
                      {...params.InputProps}
                      {...rest}
                      sx={{ ml: 1, flex: 1 ,fontSize:14 }}
                      placeholder="Search For Games"
                      value={searchName}
                      onChange={onChangeSearchName}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          findByName();
                        }
                      }}
                    />
                  );
                }}
              />
              <IconButton
                onClick={findByName}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon fontSize="small"/>
              </IconButton>
            </Paper>
          </div>
          <div>
          
          <Box sx={{ minWidth: 100,maxWidth: 120 }}>
              <FormControl fullWidth size="small" >
                <InputLabel sx={{fontSize:14}}>Sort By</InputLabel>
                <Select
                  label="Sort By"
                  value={sortOption}
                  onChange={handleChangeSortOption}
                  sx={{ borderRadius: 2, boxShadow: 1,fontSize:14 }}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 260,width:155 } },
                  }}
                  
                >
                  {sortOptions.map((sortOption) => {
                    return (
                      <MenuItem
                      sx={{
                      
                         fontSize: 14,
                       }}
                        key={sortOption}
                        value={sortOption}
                
                      >
                        {" "}
                        {sortOption}{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>

         
          <div style={{ marginTop: 15,marginRight:10 }}>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{fontSize:14}}>Genre</InputLabel>
                <Select
                  label="Genre"
                  value={searchGenre}
                  onChange={handleChangeGenre}
                  sx={{ borderRadius: 2, boxShadow: 1,fontSize:14  }}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 250,width:175 } },
                  }}
                  
                >
                  {genres.map((genre) => {
                    return (
                      <MenuItem key={genre} value={genre} sx={{           
                         fontSize: 14,
                       }}>
                        {" "}
                        {genre}{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div style={{ marginTop:15 }}>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{fontSize:14}}>Platform</InputLabel>
                <Select
                  label="Platform"
                  value={searchPlatform}
                  onChange={handleChangePlatform}
                  sx={{ borderRadius: 2, boxShadow: 1 ,fontSize:14 }}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 250,width:175 } },
                  }}
                >
                  {platforms.map((platform) => {
                    return (
                      <MenuItem
                      sx={{
                       
                         fontSize: 14,
                
                       }}
                        key={platform}
                        value={platform}
                      >
                        
                        {platform}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>

        </div>
      </div>
      <Card sx={{ boxShadow: "0" }}>
        <div
          className="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {restaurants.map((restaurant) => {
            var g = [];
            for (let i = 0; i < restaurant.genres.length; i++) {
              g.push(restaurant.genres[i].name);
            }
            g = g.join(", ");
            var p = [];
            for (let i = 0; i < restaurant.platforms.length; i++) {
              p.push(restaurant.platforms[i].platform.name);
            }
            p = p.join(", ");
            return (
              <Card
                key={restaurant._id}
                sx={{
                  ":hover": { boxShadow: 8 },
                  width: "85vw",
                  height: 350,
                  marginBottom: 2.5,
                  marginTop: 2.5,
                  marginLeft: 2,
                  marginRight: 2,
                  padding: 0,
                  borderRadius: 5,
                  boxShadow: 3,
                }}
              >
                <CardActionArea
                  href={"/games/" + restaurant._id}
                >
                  <CardMedia
                    component="img"
                    height="180"
                    image={restaurant.background_image}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {restaurant.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={12}
                      color="text.secondary"
                    >
                      <strong>Released Date: </strong>
                      {restaurant.released}
                      <br />
                      <strong>Genre(s): </strong>
                      {g}
                      <br />
                      <strong>Platform(s): </strong>
                      {p}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}

          <Divider>
            <SportsEsportsIcon />
          </Divider>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <Pagination
          count={Math.ceil(totalResults / 8)}
          color="primary"
          page={page}
          onChange={handleChange}
        />
      </div>
      <Footer />
    </div>
  );
};

export default RestaurantsListM;
