import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RestaurantDataService from "../../services/restaurant";
import { Box } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import Rating from "@mui/material/Rating";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

export default function FormDialogM({ id, game, image }) {
  let initialTextState = "";
  let initialTitleState = "";
  const [open, setOpen] = useState(false);
  const { user } = useAuth0();
  const [review, setReview] = useState(initialTextState);
  const [title, setTitle] = useState(initialTitleState);
  const [time, setTime] = useState(0);
  const [star, setStar] = useState(3);

  const [error, setError] = useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
  };

  const refresh = () => {
    setOpen(false);
    document.location.reload();
  };

  const handleTextChange = (event) => {
    if (event.target.value !== "") {
      setError(undefined);
    }
    setReview(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const saveReview = () => {
    if (review === "") {
      setError(review === "");
      return;
    }

    var data = {
      title: title,
      text: review,
      time: time,
      star: star,
      name: user.name,
      user_id: user.sub,
      restaurant_id: id,
      user_pic: user.picture,
      likes: [],
      game: game,
      image: image,
      email: user.email,
    };

    RestaurantDataService.createReview(data)
      .then((response) => {
        refresh();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Fab
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          height:40,
          bottom: 25,
          right: 14,
          fontSize: 14,
          textTransform: "none",
          boxShadow:4,
        }}
        variant="extended"
        color="primary"
      >
        <AddIcon fontSize="small" /> Review
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 10 },//height:420
        }}
      >
     
        {user ? (
          <div>
            <DialogContent sx={{padding:2}}>
            <Typography sx={{fontSize:18,marginBottom:2,marginLeft:1.25}}>Add your review</Typography>
              <Grid container>
                <Grid item xs={6.25}>
                  <TextField
                  size="small"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      style: {fontSize: "14px"}
                    }}
                    value={time}
                    sx={{ m: 1, width: "15ch" }}
                    onChange={handleTimeChange}
                    label="Play Time (Hours)"
                    InputLabelProps={{style: {fontSize: "14px"}}}
                  />
                </Grid>
                <Grid item xs={5.75}>
                  <Typography
                    sx={{ marginLeft: 3.5 }}
                    variant="body3"
                    color="text.secondary"
                    fontSize={14}
                  >
                    Your Rating
                  </Typography>
                  <Box >
                  <Rating
                    size="small"
                    name="simple-controlled"
                    value={star}
                    sx={{ marginLeft: 2.5 }}
                    onChange={(event, newStar) => {
                      setStar(newStar);
                    }}
                  /></Box>
                </Grid>
              </Grid>
              <TextField
              size="small"
                value={title}
                sx={{ m: 1, width: "27.5ch" }}
                onChange={handleTitleChange}
                label="Title"
                InputLabelProps={{style: {fontSize: "14px"}}}
                InputProps={{style: {fontSize: "14px"}}}
              />
              <br />
              <TextField
                size="small"
                sx={{ m: 1, width: "27.5ch" }}
                label="Content"
                required
                value={review}
                onChange={handleTextChange}
                helperText={error && "This field is required!"}
                error={error}
                multiline
                rows={6}
                InputLabelProps={{style: {fontSize: "14px"}}}
                InputProps={{style: {fontSize: "14px"}}}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={saveReview}
                sx={{
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <DialogTitle fontSize={18}>Add your review</DialogTitle>
            <Box sx={{ height: 35, width: 300 }}>
                <Typography
                  sx={{ marginLeft: 3 }}
                  variant="body3"
                  color="text.secondary"
                  fontSize={15}
                >
                  Please sign in.
                </Typography>
              </Box>
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                Close
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
}
