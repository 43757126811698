import React, { useState, useEffect } from "react";
import RestaurantDataService from "../../services/restaurant";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FooterM from "./footer_m";

import "./style_m.css"
import TgaPic from "../images/tga.jpeg"

import { CardActionArea } from "@mui/material";

const TgaM = (props) => {
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    find(1,"tga");
  }, []);


  const find = (query, by) => {
    RestaurantDataService.find(query, by)
      .then((response) => {
        setRestaurants(response.data.restaurants);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  return (
    <div style={{marginTop:70}}>
      <center>
        <img src={TgaPic} alt="poster" className="tga" style={{
                  width: "92vw",
                  height: 250,
                  borderRadius: 10,
                }}></img></center>
        <Card sx={{boxShadow:"0"}}>
      <div
        className="row"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        {restaurants.map((restaurant) => {
          var g = [];
          for (let i = 0; i < restaurant.genres.length; i++) {
            g.push(restaurant.genres[i].name);
          }
          g = g.join(', ');
          var p = [];
          for (let i = 0; i < restaurant.platforms.length; i++) {
            p.push(restaurant.platforms[i].platform.name);
          }
          p = p.join(', ');
          return (
            <Card
              key={restaurant._id}
              sx={{
                ":hover": { boxShadow: 8 },
                width: "85vw",
                height:350,
                marginBottom: 2.5,
                marginTop: 2.5,
                marginLeft: 2,
                marginRight: 2,
                padding: 0,
                borderRadius: 4,
                boxShadow: 3,
              }}
            >
              <CardActionArea href={"/games/" + restaurant._id}>
                <CardMedia
                  component="img"
                  height="180"
                  image={restaurant.background_image}
                /><Grid item xs={12}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {restaurant.name}
                  </Typography>
                  <Typography variant="body2" fontSize={12} color="text.secondary">
                    <strong>Released Date: </strong>
                    {restaurant.released}
                    <br />
                    <strong>Genre(s): </strong>
                    {g}
                    <br />
                    <strong>Platform(s): </strong>
                    {p}
                    <br /><br /><br /><br />
                  </Typography>
                </CardContent>
                </Grid>
              </CardActionArea>
            </Card>
          );
        })}

        <Divider>
          <EmojiEventsIcon/>
        </Divider>
      </div><FooterM/></Card>
    </div>
  );
};

export default TgaM;
