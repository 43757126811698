import React from "react";
import { Typography } from "@mui/material";

export default function Footer() {
  return (
    <div style={{marginBottom:30,marginTop:30}}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontSize={10} variant="body2" color="text.secondary">
          Made with ❤️ by{" "}
          <a href="https://www.linkedin.com/in/zikunwang/" target="_blank" rel="noreferrer">Zikun Wang</a>{" & "}        
          <a href="https://www.linkedin.com/in/ying-cao-7bb603168/" target="_blank" rel="noreferrer">Ying Cao</a>
          . Powered by <a href="https://rawg.io/apidocs" target="_blank" rel="noreferrer">RAWG</a>.
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontSize={10} variant="body2" color="text.secondary">
          © 2022 GameBase. All rights reserved.
        </Typography>
      </div>
    </div>
  );
}
