import React from "react";
import { useEffect,useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import Menu from "@mui/material/Menu";
import LogoutIcon from '@mui/icons-material/Logout';
import RestaurantDataService from "../../services/restaurant";
import ProfileM from "./profile_m";



const UserInfoM = ({user}) => {
    const [profile,setProfile] = useState(null);
    const [loaded,setLoaded] = useState(false);
 const {logout} = useAuth0();
  
 const getProfile = (user_id) => {
    RestaurantDataService.getProfile(user_id)
      .then((response) => { 
        if (response.data.profile.length > 0){
            
            setProfile(response.data.profile[0])
            
        }
        setLoaded(true)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getProfile(user.sub)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<div style={{ display: "flex", flexDirection: "row" }}>
  <Button
    aria-label="more"
    id="long-button"
    aria-controls={open ? "long-menu" : undefined}
    aria-expanded={open ? "true" : undefined}
    aria-haspopup="true"
    onClick={handleClick}
  >{loaded &&(
    <Box
      component="img"
      sx={{
        ":hover": { boxShadow: 4 },
        height: 42.5,
        width:42.5,
        borderRadius:50,
        boxShadow: 2,
      }}
      src={  profile ? profile.user_pic : user.picture}
    />)}
  </Button>
  <Menu
    id="fade-menu"
    MenuListProps={{
      "aria-labelledby": "fade-button",
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    PaperProps={{
      style: { borderRadius: 10, width:100 }
    }}
  >
    <ProfileM user={user} setAnchorEl={setAnchorEl}/>
    <Button sx={{
           fontStyle: { color: "black" },
            width:100,
            height:35,
            textTransform: "none",
            fontSize: 14,
            justifyContent: "flex-start",
          }} onClick={() => logout()}><LogoutIcon fontSize="small" sx={{ marginLeft:0.5,marginRight: 0.5  }}/>
     Sign out </Button>
  </Menu>
</div>)
    
};

export default UserInfoM;