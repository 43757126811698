import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { Checkbox } from "@mui/material";
import RestaurantDataService from "../../services/restaurant";
import { FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@mui/system";
import { DialogTitle } from "@mui/material";

export default function LikeButtonM({ likesParam, user, review_id, isLoading}) {
  const [likes, setLikes] = useState(likesParam);
  const [likedByUser, setLikedByUser] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLikes(likesParam);
    
    if (user && likesParam.has(user.sub)) {
        setLikedByUser(true);    
      }else{
        setLikedByUser(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user,review_id]);


  const getLikes = (review_id, checked) => {
    RestaurantDataService.getLikes(review_id)
      .then((response) => {
        updateLikes(checked, response.data.likes);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateLikes = (checked, l) => {
    let likes = new Set(l);
    if (checked) {
      likes.add(user.sub);
      setLikes(likes);
    } else {
      likes.delete(user.sub);

      setLikes(likes);
    }

    var data = {
      likes: Array.from(likes),
      review_id: review_id,
    };

    RestaurantDataService.updateLikes(data)
      .then((response) => {})
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event) => {
    if (user) {
      setLikedByUser(event.target.checked);
      getLikes(review_id, event.target.checked);
    }
  };

  return (
    <div>
      {!isLoading &&
        (user ? (
          <div style={{ display: "flex" }}>
            <FormControlLabel 
              control={
                <Checkbox
                size="small"
                  checked={likedByUser}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleChange}
                  color="error"
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                />
              }
              label={
                <Typography variant="body1" fontSize={16}>
                  {likes.size}
                </Typography>
              }
            />
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={false}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickOpen}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                />
              }
              label={
                <Typography variant="body1" fontSize={16}>
                  {likes.size}
                </Typography>
              }
            />
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
            >
              <DialogTitle fontSize={18}>Like this review</DialogTitle>
              <Box sx={{ height: 35, width: 300 }}>
                <Typography
                  sx={{ marginLeft: 3 }}
                  variant="body3"
                  color="text.secondary"
                  fontSize={15}
                >
                  Please sign in.
                </Typography>
              </Box>
              <DialogActions>
                <Button onClick={handleClose} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        ))}
    </div>
  );
}
