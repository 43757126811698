import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Rating from "@mui/material/Rating";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import "./style.css";

export default function CardExpanded({ id, review, time,userName,userPic }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogContent>
          <div>
            <Grid container>
              <Grid item xs={1.25}>
                <Box
                  component="img"
                  sx={{
                    height: 50,
                    width: 50,
                    borderRadius: 50,
                  }}
                  src={userPic}
                />
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ marginTop: 0.5 }}>
                  <Typography gutterBottom variant="body3">
                    {userName}
                  </Typography>
                </Box>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  {time}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    height: 30,
                    backgroundColor: "#2196f3",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: 3,
                    marginTop: 1,
                  }}
                >
                  <strong style={{ color: "white", fontSize: 12 }}>
                    {review.time + " hrs"}
                  </strong>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ marginTop: 1, marginBottom: 3 }}>
              <Rating name="read-only" value={review.star} readOnly />
              <Typography
                gutterBottom
                variant="h5"
                fontSize={25}
                component="div"
              >
                {review.title}
              </Typography>
              <div className="scrollable-div">
                <Typography variant="body1" fontSize={18} component="div">
                  {review.text}
                </Typography>
              </div>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ "&:hover": {
                              backgroundColor:"white"
                            }}}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
