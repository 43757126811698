import React from "react";
import { Switch, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Restaurant from "./components/restaurants";
import RestaurantsList from "./components/restaurants-list";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import Tga from "./components/tga";
import EditorsPicks from "./components/editors-picks";
import { useAuth0 } from "@auth0/auth0-react";
import LoginIcon from '@mui/icons-material/Login';
import PlayersReviews from "./components/players-reviews";
import UserInfo from "./components/userInfo";
import Trending from "./components/trending";
import { useMediaQuery } from 'react-responsive';
import RestaurantsListM from "./components/mobile/restaurants-list_m";
import UserInfoM from "./components/mobile/userInfo_m";
import TrendingM from "./components/mobile/trending_m";
import TgaM from "./components/mobile/tga_m";
import RestaurantM from "./components/mobile/restaurants_m";
import EditorsPicksM from "./components/mobile/editors-picks_m";
import PlayersReviewsM from "./components/mobile/players-reviews_m";
import Menu from "@mui/material/Menu";


function App() {
  // const [user, setUser] = React.useState(null);
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<div>
    {!isMobile ?(
    <div>
        <AppBar position="fixed" >
         
          <Toolbar><Box display='flex' flexGrow={1}>
            <a
              style={{ textDecoration: "none", color: "inherit",marginTop:3}}
              href={"/games/"}
            >
              <SportsEsportsIcon fontSize="large" />
            </a>
            <Typography variant="h5" component="div" sx={{marginTop:0.5}}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href={"/games/"}
              >
                GameBase
              </a>
            </Typography>
         
            <Button
              color="inherit"
              sx={{marginLeft:2,
                
                borderRadius: 4,
                "&:hover": {
                  fontStyle: { color: "white" },
                },
                textTransform: "none",
                fontSize: 17,
              }}
              href="/tga"
            >
              🏆 TGA Collections
            </Button>
            <Button
              color="inherit"
              sx={{
                borderRadius: 4,
                "&:hover": {
                  fontStyle: { color: "white" },
                },
                textTransform: "none",
                fontSize: 17,
              }}
              href="/recommendations"
            >
              🎮 Editor's Picks
            </Button><Button
              color="inherit"
              sx={{
                borderRadius: 4,
                "&:hover": {
                  fontStyle: { color: "white" },
                },
                textTransform: "none",
                fontSize: 17,
              }}
              href="/reviews"
            >
              💬 Players' Reviews
            </Button>
            <Trending />
            </Box>
            {!isAuthenticated ? (
    <Button
      color="inherit"
      sx={{ textTransform: "none", fontSize: 17 ,borderRadius: 4}}
      onClick={() => loginWithRedirect()}
    >
      <LoginIcon sx={{marginRight:1}}/>Sign in
    </Button>
            ) : (
              <UserInfo user={user}/>
            )}
          </Toolbar>
        </AppBar>
      

      <div>
        <Switch>
          <Route exact path={["/", "/games"]} component={RestaurantsList} />
          <Route
            path="/tga"
            render={(props) => <Tga {...props}/>}
          />
          <Route
            path="/recommendations"
            render={(props) => <EditorsPicks {...props}/>}
          />
           <Route
            path="/reviews"
            render={(props) => <PlayersReviews {...props} />}
          />
          <Route
            path="/games/:id"
            render={(props) => <Restaurant {...props} />}
          />
        </Switch>
      </div>
    </div>):(
    //Mobile
    <div>
    <AppBar position="fixed" >
     
      <Toolbar><Box display='flex' flexGrow={1}>
        <a
          style={{ textDecoration: "none", color: "inherit",marginTop:3}}
          href={"/games/"}
        >
          <SportsEsportsIcon />
        </a>
        <Typography variant="h7" fontSize={18} component="div" sx={{marginTop:0.5}}>
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href={"/games/"}
          >
            GameBase
          </a>
        </Typography>
        <Button
    aria-label="more"
    id="long-button"
    aria-controls={open ? "long-menu" : undefined}
    aria-expanded={open ? "true" : undefined}
    aria-haspopup="true"
    onClick={handleClick}
    color="inherit"
          sx={{
            
            marginLeft:1,
            borderRadius: 4,
            "&:hover": {
              fontStyle: { color: "white" },
            },
            textTransform: "none",
            fontSize: 14,
          }}
  >🕹️ Discover
  </Button>
        <Menu
    id="fade-menu"
    MenuListProps={{
      "aria-labelledby": "fade-button",
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    PaperProps={{
      style: { borderRadius: 10, width:115 }
    }}
  >
    <Button sx={{
           fontStyle: { color: "black" },
           "&:hover": {
            fontStyle: { color: "black" },
          },
            width:115,
            height:35,
            textTransform: "none",
            fontSize: 14,
            justifyContent: "flex-start",
          }}  href="/tga">
     🏆 Collections </Button>

     <Button sx={{
           fontStyle: { color: "black" },
           "&:hover": {
            fontStyle: { color: "black" },
          },
            width:115,
            height:35,
            textTransform: "none",
            fontSize: 14,
            justifyContent: "flex-start",
          }}  href="/recommendations">
     🎮 Featured </Button>

     <Button sx={{
           fontStyle: { color: "black" },
           "&:hover": {
            fontStyle: { color: "black" },
          },
            width:115,
            height:35,
            textTransform: "none",
            fontSize: 14,
            justifyContent: "flex-start",
          }}  href="/reviews">
      💬 Reviews</Button>

     <TrendingM close={handleClose}/>
  </Menu>
       
        </Box>
        {!isAuthenticated ? (
<Button
  color="inherit"
  sx={{ textTransform: "none", fontSize: 14 ,borderRadius: 4}}
  onClick={() => loginWithRedirect()}
>
  <LoginIcon fontSize="small" sx={{marginRight:1}}/>Sign in
</Button>
        ) : (
          <UserInfoM user={user}/>
        )}
      </Toolbar>
    </AppBar>

  <div>
    <Switch>
      <Route exact path={["/", "/games"]} component={RestaurantsListM} />
      <Route
        path="/tga"
        render={(props) => <TgaM {...props}/>}
      />
      <Route
        path="/recommendations"
        render={(props) => <EditorsPicksM {...props}/>}
      />
       <Route
        path="/reviews"
        render={(props) => <PlayersReviewsM {...props} />}
      />
      <Route
        path="/games/:id"
        render={(props) => <RestaurantM {...props} />}
      />
    </Switch>
  </div>
</div>)}</div>
  );
}

export default App;
