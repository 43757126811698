import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RestaurantDataService from "../services/restaurant";
import { Box } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import Rating from "@mui/material/Rating";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

export default function FormDialog({ id, game, image }) {
  let initialTextState = "";
  let initialTitleState = "";
  const [open, setOpen] = useState(false);
  const { user } = useAuth0();
  const [review, setReview] = useState(initialTextState);
  const [title, setTitle] = useState(initialTitleState);
  const [time, setTime] = useState(0);
  const [star, setStar] = useState(3);
  
  const [error,setError] = useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
  };

  const refresh = () => {
    setOpen(false);
    document.location.reload();
  };

  const handleTextChange = (event) => {
    if (event.target.value !== ""){
        setError(undefined);
    }
    setReview(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const saveReview = () => {

    if (review === ""){
        setError(review==="")
        return
    }

    var data = {
      title: title,
      text: review,
      time: time,
      star: star,
      name: user.name,
      user_id: user.sub,
      restaurant_id: id,
      user_pic: user.picture,
      likes : [],
      game: game,
      image: image,
      email:user.email
    };

    RestaurantDataService.createReview(data)
      .then((response) => {
        refresh();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        sx={{
          borderRadius: 4,
          height: 40,
          "&:hover": {
            fontStyle: { color: "white" },
          },
          textTransform: "none",
          fontSize: 16,
        }}
      >
        <AddIcon/> Review
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle>Add your review</DialogTitle>
        {user ? (
          <div>
            <DialogContent>
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    InputProps={{
                        inputProps: { 
                            min: 0
                        }
                    }}
                    value={time}
                    sx={{ m: 1, width: "20ch" }}
                    onChange={handleTimeChange}
                    label="Play Time (Hours)"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ marginLeft: 5 }}
                    variant="body3"
                    color="text.secondary"
                  >
                    Your Rating
                  </Typography>
                  <Rating
                    name="simple-controlled"
                    value={star}
                    sx={{ marginLeft: 3 }}
                    onChange={(event, newStar) => {
                      setStar(newStar);
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                value={title}
                sx={{ m: 1, width: "45ch" }}
                onChange={handleTitleChange}
                label="Title"
              />
              <br />
              <TextField
                sx={{ m: 1, width: "45ch" }}
                label="Content"
                required
                value={review}
                onChange={handleTextChange}
                helperText={error && "This field is required!" }
                error={error}
                multiline
                rows={10}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Cancel</Button>
              <Button onClick={saveReview} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Submit</Button>
            </DialogActions>
          </div>
        ) : (
          <div>
              <Box sx={{ height: 50, width: 300 }}>
                <Typography
                  sx={{ marginLeft: 3 }}
                  variant="body3"
                  color="text.secondary"
                >
                  Please sign in.
                </Typography>
              </Box>
            <DialogActions>
              <Button onClick={handleClose} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Close</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
}
