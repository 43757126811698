import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import EmailIcon from "@mui/icons-material/Email";
import GamesIcon from '@mui/icons-material/Games';
import "./style.css";
import PersonIcon from '@mui/icons-material/Person';
import RestaurantDataService from "../services/restaurant";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function PlayerProfile({ email,userInfo,id,login_user,fromProfile=false ,
    closeWindows=undefined}) {
   
  const name = userInfo[0]
  const pic = userInfo[1]
  const description = (userInfo[2] === "" ? "N/A":userInfo[2])
  const backdropStyle = fromProfile ? { style: { backgroundColor: "transparent" } } : {};
  const fGame = (userInfo[3] === "" ? "N/A":userInfo[3])
  const showEmail = userInfo[4]
  const [likes, setLikes] = useState(0);
  const [reviewCount,setReviewCount] = useState(0);
  const [follow, setFollow] = useState({user_id:"",following:{},followedBy:{}});
  const [showFollow, setShowFollow] = useState(true);
  const [loaded,setLoaded] = useState(false)
  const [open, setOpen] = useState(false);
  const [followers,setFollowers] = useState(0);

  const [openDialogue, setOpenDialogue] = useState(false);

  const handleClickOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const [openDialogue2, setOpenDialogue2] = useState(false);

  const handleClickOpenDialogue2 = () => {
    setOpenDialogue2(true);
  };

  const handleCloseDialogue2 = () => {
    setOpenDialogue2(false);
  };

  const handleClickOpen = () => {
    setLoaded(false);
    getFollow(id);
    getUserLikesReviews(id);
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAll = () => {
    closeWindows();
    setOpen(false);
  };

  const addFollow = () => {
    setShowFollow(false);
    setFollowers(followers+1)

    var data = {
      a_id: login_user.sub,
      a_name: login_user.name,
      a_picture: login_user.picture,
      a_email: login_user.email,
      b_id: id,
      b_name: userInfo[0],
      b_picture: userInfo[1],
      b_email: email,
    };
  
    RestaurantDataService.addFollow(data)
      .then((response) => {
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const unfollow = () => {
    setShowFollow(true);
    setFollowers(followers-1);
    setOpenDialogue2(false);

    var data = {
      a_id: login_user.sub,
      b_id: id,
    };

    RestaurantDataService.unfollow(data)
      .then((response) => {
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUserLikesReviews = (user_id) => {
    RestaurantDataService.getUserLikesReviews(user_id)
      .then((response) => {
        if (response.data.likes.length > 0) {
          setLikes(response.data.likes[0].count);
        } 
         setReviewCount(response.data.review_count);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getFollow = (user_id) => {
    RestaurantDataService.getFollow(user_id)
      .then((response) => {
        if (response.data.follow.length > 0) {
          setFollow(response.data.follow[0]);
          setFollowers(Object.keys(response.data.follow[0].followedBy).length);
          if(login_user && login_user.sub in response.data.follow[0].followedBy){
              setShowFollow(false);
          }else{
            setShowFollow(true);
          }
        }
        setLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  


  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <PersonIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps={backdropStyle}
        PaperProps={{
          style: { borderRadius: 10, width: 400,height:455},
        }}
      >
    {fromProfile && (<DialogTitle sx={{height:10}}><IconButton sx={{marginLeft:-3,marginTop:-2}} disableRipple onClick={handleClose}><ArrowBackIosNewIcon fontSize="small" /></IconButton></DialogTitle>)}
      <DialogContent>
    
          <div>
            <Grid container>
              <Grid item xs={3}>
               
                  <Box
                    component="img"
                    sx={{
                      height: 70,
                      width: 70,
                      borderRadius: 50,
                    }}
                    src={pic}
                  />
                  <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Typography sx={{marginLeft:1,fontWeight: 'bold'}} variant="body1" fontSize={14} component="div">
                      Following
                </Typography>
                <Typography variant="body1" sx={{marginLeft:4.5}} fontSize={14} component="div">
                {Object.keys(follow.following).length}
                </Typography>
                 </div>
                 
                 <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Typography sx={{marginLeft:1,fontWeight: 'bold'}} variant="body1" fontSize={14} component="div">
                      Followers
                </Typography>
                <Typography variant="body1" sx={{marginLeft:4.5}} fontSize={14} component="div">
                {followers}
                </Typography>
                 </div>
                 <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Typography sx={{marginLeft:1.5,fontWeight: 'bold'}} variant="body1" fontSize={14} component="div">
                      Reviews
                </Typography>
                <Typography variant="body1" sx={{marginLeft:4.5}} fontSize={14} component="div">
                {reviewCount}
                </Typography>
                 </div>
                      
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 10,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            color="error"
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                          />
                        }
                        label={
                          <Typography variant="body1" fontSize={16}>
                            {likes}
                          </Typography>
                        }
                      />
                    </div>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={2}>
                    <AccountCircle
                      sx={{
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                        marginTop: 2.5,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                  <Typography fontSize={16} sx={{ marginTop: 2.5 }}>
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 1.5 }}>
                  <Grid item xs={2}>
                    <LightbulbIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                  <div className="scrollable-div" style={{width:200,height:150,padding:14}}>
                  <Typography fontSize={16}>
                      {description}
                    </Typography></div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2}>
                    <GamesIcon
                      sx={{
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                        marginTop: 2.5,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                  <Typography fontSize={16} sx={{ marginTop: 2.5}}>
                      {fGame}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sx={{ marginTop: 1 }}>
                    <EmailIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography fontSize={16} sx={{ marginTop: 1.6 }}>
                      {showEmail ? email : "Hidden"}
                    </Typography>
                  </Grid>
                </Grid>
                {(loaded && (!login_user || login_user.sub !== id) )&&(<div><Button
                  variant="contained"
                  
                  sx={{
                    width:80,
                    height:35,
                    color:"white",
                    backgroundColor: showFollow ? ("#42a5f5") :("#26a69a"),
                    borderRadius: 3.5,
                    marginLeft: 4,
                    marginTop: 2,
                    "&:hover": {
                      backgroundColor:showFollow ? ("#2196f3") : ("#009688"),
                    },
                    textTransform: "none",
                    fontSize: 14,
                  }}                
                  onClick={login_user ? (showFollow ? (addFollow) :(handleClickOpenDialogue2) ) :(handleClickOpenDialogue)}
                >
                  {showFollow ? ("Follow") : ("Following")}
                </Button>
                <Dialog
              open={openDialogue}
              onClose={handleCloseDialogue}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
            >
              <DialogTitle>Follow this user</DialogTitle>
              <Box sx={{ height: 50, width: 300 }}>
                <Typography
                  sx={{ marginLeft: 3 }}
                  variant="body3"
                  color="text.secondary"
                >
                  Please sign in.
                </Typography>
              </Box>
              <DialogActions>
                <Button onClick={handleCloseDialogue} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Close</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialogue2}
              onClose={handleCloseDialogue2}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
            >
              <DialogTitle>Alert</DialogTitle>
              <Box sx={{ height: 75, width: 300,paddingLeft:3,paddingRight:3 }}>
                <Typography
                  variant="body3"
                  color="text.secondary"
                >
                  Are you sure to unfollow {name}?
                </Typography>
              </Box>
              <DialogActions>
                <Button onClick={handleCloseDialogue2} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Cancel</Button>
                <Button onClick={unfollow} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Confirm</Button>
              </DialogActions>
            </Dialog>
                </div>)}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={fromProfile ? handleCloseAll : handleClose} 
          sx={{ "&:hover": {
            backgroundColor:"white"
          }}}
          >Close</Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}