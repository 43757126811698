import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RestaurantDataService from "../services/restaurant";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Rating } from "@mui/material";

export default function MenuCustomized({
  currentReview,
  deleteReview,
  index,
  id,
}) {
  let initialTextState = currentReview.text;
  let initialTitleState = currentReview.title;
  let initialTime = currentReview.time;
  let initialStar = currentReview.star;

  const [openWindow, setOpenWindow] = useState(false);
  const { user } = useAuth0();
  const [review, setReview] = useState(initialTextState);
  const [title, setTitle] = useState(initialTitleState);
  const [time, setTime] = useState(initialTime);
  const [star, setStar] = useState(initialStar);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [error,setError] = useState(undefined);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpenWindow(true);
  };

  const handleCloseWindow = () => {
    setOpenWindow(false);
    setError(undefined);
  };


  const refresh = () => {
    setOpenWindow(false);
    window.location.reload();
  };

  const handleTextChange = (event) => {
    if (event.target.value !== ""){
        setError(undefined);
    }
    setReview(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const saveReview = () => {
    
    if (review === ""){
        setError(review==="")
        return
    }

    var data = {
      title: title,
      text: review,
      time: time,
      star: star,
      name: user.name,
      user_id: user.sub,
      restaurant_id: id,
    };

    data.review_id = currentReview._id;
    RestaurantDataService.updateReview(data)
      .then((response) => {
        refresh();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
            style: { borderRadius: 10 }
          }}
      >
        <MenuItem onClick={handleClickOpen} >
          <EditIcon fontSize="small" sx={{ marginRight: 1 }} />
          Edit
        </MenuItem>
        <Dialog
          open={openWindow}
          onClose={handleCloseWindow}
          PaperProps={{
            style: { borderRadius: 10 },
          }}
        >
          <DialogTitle>Edit your review</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  InputProps={{
                    inputProps: { 
                        min: 0
                    }
                }}
                  value={time}
                  sx={{ m: 1, width: "20ch" }}
                  onChange={handleTimeChange}
                  label="Play Time (Hours)"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{ marginLeft: 5 }}
                  variant="body3"
                  color="text.secondary"
                >
                  Your Rating
                </Typography>
                <Rating
                  name="simple-controlled"
                  value={star}
                  sx={{ marginLeft: 3 }}
                  onChange={(event, newStar) => {
                    setStar(newStar);
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              value={title}
              sx={{ m: 1, width: "45ch" }}
              onChange={handleTitleChange}
              label="Title"
            />
            <br />
            <TextField
              sx={{ m: 1, width: "45ch" }}
              label="Content"
              required
              value={review}
              onChange={handleTextChange}
              helperText={error && "This field is required!" }
              error={error}
              multiline
              rows={10}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWindow} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Cancel</Button>
            <Button onClick={saveReview} sx={{ "&:hover": {
            backgroundColor:"white"
          }}}>Submit</Button>
          </DialogActions>
        </Dialog>

        <MenuItem onClick={() => deleteReview(currentReview._id, index)}>
          <DeleteIcon fontSize="small" sx={{ marginRight: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
