import React, { useState, useEffect } from "react";
import RestaurantDataService from "../services/restaurant";
import ExternalServices from "../services/external-services";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import Box from "@mui/material/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./style.css";
import Button from "@mui/material/Button";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import ImageShadow from "react-image-shadow";
import "react-image-shadow/assets/index.css";
import { ResponsivePie } from "@nivo/pie";
import CommentIcon from "@mui/icons-material/Comment";
import { useAuth0 } from "@auth0/auth0-react";
import Rating from "@mui/material/Rating";
import FormDialog from "./test";
import MenuCustomized from "./menu";
import CardExpanded from "./expandedCard";
import { Pagination } from "@mui/material";
import PlayerProfile from "./playerProfile";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { Divider } from "@mui/material";
import Footer from "./footer";
import LikeButton from "./like-button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core/styles";

const Restaurant = (props) => {
  const initialRestaurantState = {
    id: null,
    name: "",
    reviews: [],
  };

  const { user, isLoading } = useAuth0();
  const [restaurant, setRestaurant] = useState(initialRestaurantState);
  const [rawgGame, setRawgGame] = useState({});
  const [genres, setGenres] = useState("");
  const [platforms, setPlatforms] = useState("");
  const [developers, setDevelopers] = useState("");
  const [esrb_rating, setEsrb] = useState("");
  const [screenshots, setScreenshots] = useState([]);
  const [stores, setStores] = useState([]);
  const [mColor, setmColor] = useState("");
  const [rColor, setrColor] = useState("");
  const [gColor, setgColor] = useState("");
  const [mLink, setmLink] = useState("");
  const [tags, setTags] = useState("");
  const [publishers, setPublishers] = useState("");
  const [ratings, setRatings] = useState([]);
  const [gameBaseScore, setGameBaseScore] = useState(0);
  const [page, setPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [alignment, setAlignment] = useState("latest");

  const handleChangeAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }

    if (newAlignment === "likes") {
      setReviews(JSON.parse(sessionStorage.getItem("reviewsByLikes")));
      setPage(1);
    } else if (newAlignment === "latest") {
      setReviews(JSON.parse(sessionStorage.getItem("reviewsByDates")));
      setPage(1);
    }
  };

  var res;
  var g = [];
  var p = [];
  var d = [];
  var s = [];
  var t = [];
  var r = [];
  var pub = [];
  var st = [];
  var rGame;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // window.addEventListener(
  //   "scroll",
  //   function () {
  //     sessionStorage.setItem("scrollPosition", window.scrollY);
  //   },
  //   false
  // );

  const getRestaurant = (id) => {
    RestaurantDataService.get(id)
      .then((response) => {
        setRestaurant(response.data);
        res = response.data;
        getGenresAndPlatforms(res);
        getProfile(res.uniqueIDs);
        getRwagGame(response.data.id);
        // setTimeout(function () {
        //   window.scrollTo({
        //     top: sessionStorage.getItem("scrollPosition")
        //       ? sessionStorage.getItem("scrollPosition")
        //       : 0,
        //     behavior: "smooth",
        //   });
        // }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getReviewsByLike = (id) => {
    RestaurantDataService.getReviews("game_likes", "",id)
      .then((response) => {
        sessionStorage.setItem(
          "reviewsByLikes",
          JSON.stringify(response.data.reviews)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProfile = (ids) => {
    let queryString = "";
    for (let i = 0; i < ids.length; i++) {
      queryString += ids[i].user_id + ",";
    }

    RestaurantDataService.getProfile(queryString)
      .then((response) => {
        let info = response.data.userInfo;

        for (let i = 0; i < ids.length; i++) {
          if (!(ids[i].user_id in info)) {
            info[ids[i].user_id] = [
              ids[i].name,
              ids[i].user_pic,
              "",
              "",
              false,
            ];
          }
        }
        setUserInfo(info);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getRwagGame = (id) => {
    ExternalServices.getFromRawg(id)
      .then((response) => {
        setRawgGame(response.data);
        rGame = response.data;
        getLink(rGame);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLink = (rGame) => {
    if (rGame.id === 22511) {
      setmLink(
        "https://www.metacritic.com/game/switch/the-legend-of-zelda-breath-of-the-wild"
      );
    } else {
      setmLink(rGame.metacritic_url);
    }

    for (let i = 0; i < rGame.developers.length; i++) {
      d.push(rGame.developers[i].name);
    }
    d = d.join(", ");
    setDevelopers(d);

    for (let i = 0; i < rGame.publishers.length; i++) {
      pub.push(rGame.publishers[i].name);
    }
    pub = pub.join(", ");
    setPublishers(pub);
  };

  useEffect(() => {
    sessionStorage.setItem("reviewsByLikes", JSON.stringify([]));
    sessionStorage.setItem("reviewsByDates", JSON.stringify([]));
    getRestaurant(props.match.params.id);
    getReviewsByLike(props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const getGenresAndPlatforms = (res) => {
    for (let i = 0; i < res.genres.length; i++) {
      g.push(res.genres[i].name);
    }
    g = g.join(", ");

    for (let i = 0; i < res.platforms.length; i++) {
      p.push(res.platforms[i].platform.name);
    }
    p = p.join(", ");
    setGenres(g);
    setPlatforms(p);

    for (let i = 0; i < Math.min(res.tags.length, 10); i++) {
      t.push(res.tags[i].name);
    }
    t = t.join(", ");
    setTags(t);

    for (let i = 1; i < res.short_screenshots.length; i++) {
      s.push(res.short_screenshots[i].image);
    }
    setScreenshots(s);

    for (let i = 0; i < res.stores.length; i++) {
      st.push(res.stores[i].store);
    }
    setStores(st);

    let score = 0;

    for (let i = 0; i < res.reviews.length; i++) {
      score += res.reviews[i].star;
    }
    if (res.reviews.length > 0) {
      score /= res.reviews.length;
      score = +parseFloat(score).toFixed(2);
    }
    setGameBaseScore(score);

    sessionStorage.setItem("reviewsByDates", JSON.stringify(res.reviews));
    setReviews(res.reviews);

    if (score === 0) {
      setgColor("primary.main");
    } else if (score >= 4) {
      setgColor("success.main");
    } else if (score >= 3) {
      setgColor("warning.main");
    } else {
      setgColor("error.main");
    }

    for (let i = 0; i < res.ratings.length; i++) {
      let emoji;

      if (res.ratings[i].title === "exceptional") {
        emoji = "🤩";
      } else if (res.ratings[i].title === "recommended") {
        emoji = "😄";
      } else if (res.ratings[i].title === "meh") {
        emoji = "😕";
      } else {
        emoji = "🤮";
      }
      r.push({ id: emoji, label: "s", value: res.ratings[i].count });
    }

    setRatings(r);

    if (res.esrb_rating) {
      setEsrb(res.esrb_rating.name);
    } else {
      setEsrb("N/A");
    }

    if (!res.metacritic) {
      setmColor("primary.main");
    } else if (res.metacritic >= 80) {
      setmColor("success.main");
    } else if (res.metacritic >= 60) {
      setmColor("warning.main");
    } else {
      setmColor("error.main");
    }

    if (!res.rating) {
      setrColor("primary.main");
    } else if (res.rating >= 4) {
      setrColor("success.main");
    } else if (res.rating >= 3) {
      setrColor("warning.main");
    } else {
      setrColor("error.main");
    }
  };

  const deleteReview = (reviewId, index) => {
    RestaurantDataService.deleteReview(reviewId, user.sub)
      .then((response) => {
        setRestaurant((prevState) => {
          prevState.reviews.splice(index, 1);
          return {
            ...prevState,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 750,
  };

  const useStyles = makeStyles((theme) => ({
    root: (props) => {
      return {
        "&.Mui-selected, &.Mui-selected:hover": {
          color: "white",
          backgroundColor: "#2196f3",
        },
        color: "black",
      };
    },
  }));

  function MyToggleButton(props) {
    const { color, ...other } = props;
    const classes = useStyles({ color });
    return <ToggleButton classes={classes} {...other} />;
  }

  return (
    <div>
      <Card
        sx={{
          marginBottom: 2.5,
          marginTop: 12,
          marginLeft: 5,
          marginRight: 5,
          padding: 5,
          borderRadius: 4,
          boxShadow: 5,
        }}
      >
        <Grid container marginBottom={5}>
          <Grid item xs={5} paddingRight={5}>
            <CardMedia
              component="img"
              height="400"
              image={restaurant.background_image}
              sx={{ borderRadius: "20px", boxShadow: 10 }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                {restaurant.name}
              </Typography>
              <Typography variant="body3" color="text.secondary">
                <strong>Released Date: </strong>
                {restaurant.released}
                <br />
                <strong>Developer(s): </strong>
                {developers === "" ? "Unknown" : developers}
                <br />
                <strong>Publisher(s): </strong>
                {publishers}
                <br />
                <strong>Genre(s): </strong>
                {genres}
                <br />
                <strong>Platform(s): </strong>
                {platforms}
                <br />
                <strong>Age Rating: </strong>
                {esrb_rating}
                <br />
                <strong>Average Playtime: </strong>
                {restaurant.playtime === 0
                  ? "N/A"
                  : restaurant.playtime + " Hours"}
                <br />
                <strong>Tags: </strong>
                {tags}
                <br />
                <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <strong>Available At: </strong>
                  {stores.length === 0
                    ? "N/A"
                    : stores.map((store) => {
                        return (
                          <Button
                            key={store.name}
                            variant="contained"
                            sx={{
                              borderRadius: 3,
                              marginLeft: 0.5,
                              marginRight: 0.5,
                              marginBottom: 1,
                              "&:hover": {
                                fontStyle: { color: "white" },
                              },
                              textTransform: "none",
                              fontSize: 10,
                            }}
                            color="secondary"
                            href={"https://" + store.domain}
                            target="_blank"
                          >
                            {store.name}
                          </Button>
                        );
                      })}
                </div>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 4,
                    marginLeft: 1,
                    marginRight: 2,
                    "&:hover": {
                      fontStyle: { color: "white" },
                    },
                    textTransform: "none",
                    fontSize: 16,
                  }}
                  href={rawgGame.website}
                  target="_blank"
                >
                  Website
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 4,
                    "&:hover": {
                      fontStyle: { color: "white" },
                    },
                    textTransform: "none",
                    fontSize: 16,
                  }}
                  href={mLink}
                  target="_blank"
                >
                  Metacritic
                </Button>
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={1.5} paddingTop={8}>
            <Typography variant="h5" paddingLeft={8.5}>
              Reactions
            </Typography>
            <ResponsivePie
              data={ratings}
              theme={{ fontSize: 24 }}
              width={200}
              height={200}
              margin={{ left: 50, right: 10, bottom: 20 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              enableArcLinkLabels={false}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              arcLabel="id"
              colors={{ scheme: "set2" }}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h5" paddingLeft={2}>
              Metacritic
            </Typography>

            <Box
              sx={{
                boxShadow: 3,
                height: 65,
                width: 65,
                backgroundColor: "primary.dark",
                bgcolor: mColor,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 2,
                marginTop: 1,
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <strong style={{ color: "white", fontSize: 25 }}>
                {!restaurant.metacritic ? "N/A" : restaurant.metacritic}
              </strong>
            </Box>
            <Typography variant="h5" paddingLeft={3.5}>
              RAWG
            </Typography>
            <Box
              sx={{
                boxShadow: 3,
                height: 65,
                width: 65,
                backgroundColor: "primary.dark",
                bgcolor: rColor,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 2,
                marginTop: 1,
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <strong style={{ color: "white", fontSize: 25 }}>
                {!restaurant.rating ? "N/A" : restaurant.rating}
              </strong>
            </Box>
            <Typography variant="h5" paddingLeft={1.5}>
              GameBase
            </Typography>
            <Box
              sx={{
                boxShadow: 3,
                height: 65,
                width: 65,
                backgroundColor: "primary.dark",
                bgcolor: gColor,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 2,
                marginTop: 1,
                marginLeft: 4,
              }}
            >
              <strong style={{ color: "white", fontSize: 25 }}>
                {gameBaseScore === 0 ? "N/A" : gameBaseScore}
              </strong>
            </Box>
          </Grid>
        </Grid>
        <Grid container paddingBottom={5}>
          <Grid item xs={8}>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              marginLeft={0.5}
              marginBottom={1}
            >
              <VideogameAssetIcon sx={{ marginRight: 1 }} />
              Story & Features
            </Typography>
            <Typography variant="body1" fontSize={18}>
              {!rawgGame.description_raw ? "N/A" : rawgGame.description_raw}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          marginLeft={0.5}
          marginBottom={3}
        >
          <CollectionsIcon /> Gallery
        </Typography>

        <div>
          <Grid paddingLeft={4} paddingRight={4}>
            <Slider {...settings}>
              {screenshots.map((screenshot) => {
                return (
                  <Box
                    key={screenshot}
                    sx={{
                      paddingBottom: 10,
                    }}
                  >
                    <ImageShadow
                      src={screenshot}
                      width={460}
                      shadowBlur={10}
                      shadowRadius={20}
                    />
                  </Box>
                );
              })}
            </Slider>
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={1.5}>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              marginLeft={0.5}
              marginBottom={4}
            >
              <CommentIcon sx={{ marginRight: 1 }} />
              Reviews
            </Typography>
          </Grid>
          <Grid item xs={9.25}>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChangeAlignment}
              sx={{borderRadius:4,boxShadow:2}}
            >
              <MyToggleButton
                value="latest"
                sx={{
                  borderRadius: 4,
                  textTransform: "none",
                  fontSize: 16,
                  height: 40,
                }}
              >
                Latest
              </MyToggleButton>
              <MyToggleButton
                value="likes"
                sx={{
                  borderRadius: 4,
                  textTransform: "none",
                  fontSize: 16,
                  height: 40,
                }}
              >
                Most Likes
              </MyToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={1.25}>
            <FormDialog
              id={props.match.params.id}
              game={restaurant.name}
              image={restaurant.background_image}
            />
          </Grid>
        </Grid>

        <div
          className="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {reviews.length > 0 ? (
            reviews.slice((page - 1) * 6, page * 6).map((review, index) => {
              const t = new Date(review.date)
                .toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
                .split(", ");
              const m = t[1].split(/:| /) 
              const time = m[0] + ":" + m[1] + " "+ m[3]+  " • " + t[0];
              
              const wordLimit = (review.title === "" ? 55 : 45);
              let reviewText = review.text.split(" ").slice(0, wordLimit).join(" ");
              if (reviewText !== review.text) {
                reviewText += " ...";
              }
              const canEdit = user && user.sub === review.user_id;

              return (
                //<Grid item xs={4}>
                <Card
                  key={review._id}
                  sx={{
                    ":hover": { boxShadow: 8 },
                    height: 360,
                    width: 460,
                    marginBottom: 3.5,
                    marginRight: 2.5,
                    marginLeft: 2.5,
                    padding: 3,
                    borderRadius: 5,
                    boxShadow: 3,
                  }}
                >
                  <div>
                    <Grid container>
                      <Grid item xs={1.6}>
                        {userInfo[review.user_id] && (
                          <Box
                            component="img"
                            sx={{
                              height: 50,
                              width: 50,
                              borderRadius: 50,
                            }}
                            src={userInfo[review.user_id][1]}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4.5}>
                        <Box sx={{ marginTop: 0.5 }}>
                          {userInfo[review.user_id] && (
                            <Typography gutterBottom variant="body3">
                              {userInfo[review.user_id][0]}
                            </Typography>
                          )}
                        </Box>
                        <Typography
                          gutterBottom
                          variant="body2"
                          fontSize={13}
                          color="text.secondary"
                        >
                          {time}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          sx={{
                            height: 30,
                            backgroundColor: "#2196f3",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: 3,
                            marginTop: 1,
                          }}
                        >
                          <strong style={{ color: "white", fontSize: 12 }}>
                            {review.time + " hrs"}
                          </strong>
                        </Box>
                      </Grid>
                      <Grid item xs={canEdit ? 0.9 : 1.9}></Grid>
                      <Grid item xs={1}>
                        {(userInfo[review.user_id] && !isLoading) && (
                          <PlayerProfile
                            email={review.email}
                            userInfo={userInfo[review.user_id]}
                            id={review.user_id}
                            login_user={user}
                          />
                        )}
                      </Grid>

                      <Grid item xs={1}>
                        <div>
                          {userInfo[review.user_id] && (
                            <CardExpanded
                              id={props.match.params.id}
                              review={review}
                              time={time}
                              userName={userInfo[review.user_id][0]}
                              userPic={userInfo[review.user_id][1]}
                            />
                          )}
                        </div>
                      </Grid>

                      {canEdit && (
                        <Grid item xs={1}>
                          <div>
                            <MenuCustomized
                              currentReview={review}
                              deleteReview={deleteReview}
                              index={index}
                              id={props.match.params.id}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>

                    <Box sx={{ marginTop: 1 }}>
                      <Grid container>
                        <Grid item xs={10.5}>
                          <Rating
                            name="read-only"
                            value={review.star}
                            readOnly
                          />
                        </Grid>
                        <Grid item xs={1.5} sx={{ marginTop: -1.5 }}>
                          <LikeButton
                            likesParam={new Set(review.likes)}
                            user={user}
                            review_id={review._id}
                            isLoading={isLoading}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Typography
                      gutterBottom
                      variant="h5"
                      fontSize={25}
                      component="div"
                    >
                      {review.title}
                    </Typography>
                    <Typography variant="body1" fontSize={18} component="div">
                      {reviewText}
                    </Typography>
                  </div>
                </Card> //</Grid>
              );
            })
          ) : (
            <Box sx={{ marginLeft: 1.5 }}>
              <Typography variant="body1" fontSize={22}>
                No reviews yet.
              </Typography>
            </Box>
          )}
        </div>
        {reviews.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Pagination
              count={Math.ceil(reviews.length / 6)}
              color="primary"
              page={page}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
      <Divider>
        <SportsEsportsIcon />
      </Divider>
      <Footer />
    </div>
  );
};

export default Restaurant;
