import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

 

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain="dev-dkyndjol.us.auth0.com"
      clientId="2sRT51AXFYWNVKsTMH0xIWm8GSrcAS6a"
      redirectUri={window.location.origin}
      cacheLocation='localstorage'
    useRefreshTokens= {true}>
      
      <App />
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
