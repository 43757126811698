import http from "../http-common";

class RestaurantDataService {
  getAll(page,mobile="") {
    if (mobile === "mobile"){
      return http.get(`restaurants?page=${page}&mobile=1`);
    }else{
    return http.get(`restaurants?page=${page}`);
    }
  }

  get(id) {
    return http.get(`/restaurant?id=${id}`);
  }

  getReviews(by,follow_ids,id="") {
    return http.get(`/reviews?by=${by}&id=${id}&user_id=${follow_ids}`);
  }

  find(query, by = "name", page = 0) {
    return http.get(`restaurants?${by}=${query}&page=${page}`);
  } 

  findCombo(query, page = 0,mobile="") {
    let queryString =  "";
    for (let i=0;i<query.length;i++){
      queryString +=  query[i][0] +"="+ query[i][1]+"&"
    }
    queryString += `page=${page}`

    if (mobile === "mobile"){
      queryString += "&mobile=1"
    }

    return http.get("restaurants?"+queryString);
  } 

  createReview(data) {
    return http.post("/review_new", data);
  }

  updateReview(data) {
    return http.put("/review_edit", data);
  }

  deleteReview(id, userId) {
    return http.delete(`/review_delete?id=${id}`, {data:{user_id: userId}});
  }

  createProfile(data) {
    return http.post("/profile_new", data);
  }

  updateProfile(data) {
    return http.put("/profile_edit", data);
  }

  getProfile(user_id) {
    return http.get(`profile?user_id=${user_id}`);
  } 

  getGenres(id) {
    return http.get(`/genres`);
  }

  getPlatforms(id) {
    return http.get(`/platforms`);
  }

  getAllGameNames() {
    return http.get(`/game_names`);
  }

  getLikes(review_id) {
    return http.get(`/likes?review_id=${review_id}`);
  }

  updateLikes(data) {
    return http.put("/likes_update", data);
  }

  getUserLikesReviews(id) {
    return http.get(`/user_likes_reviews?id=${id}`);
  }

  addFollow(data) {
    return http.post("/follow_add", data);
  }

  getFollow(id) {
      return http.get(`/follow?id=${id}`);
  }

  getStats() {  
    return http.get("/stats");
  }

  unfollow(data) {
    return http.put("/follow_delete",data);
}

}

export default new RestaurantDataService();