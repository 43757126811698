import React, { useState } from "react";
import RestaurantDataService from "../../services/restaurant";
import { Dialog } from "@mui/material";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { DialogContent } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DialogActions } from "@mui/material";
import { IconButton } from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { useAuth0 } from "@auth0/auth0-react";
import PlayerProfileM from "./playerProfile_m";

const TrendingM = ({close}) => {
  const {user} = useAuth0();
  const [topPlayers, setTopPlayers] = useState([]);
  const [topGames, setTopGames] = useState([]);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const medals = ["🥇", "🥈", "🥉"];

  const getStats = () => {
    RestaurantDataService.getStats()
      .then((response) => {
        setTopPlayers(response.data.top_players);
        setTopGames(response.data.top_games);
        let ids = [];
        let data = {};
        for (let i = 0; i < response.data.top_players.length; i++) {
          ids.push(response.data.top_players[i]._id);
          data[response.data.top_players[i]._id] = [
            response.data.top_players[i].name,
            response.data.top_players[i].user_pic,
            response.data.top_players[i].email,
          ];
        }
        getProfile(ids, data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClickOpen = () => {
    getStats();
    setOpen(true);
  };

  const handleClose = () => {
    close();
    setOpen(false);
  };

  const getProfile = (ids, data) => {
    let queryString = ids.join();

    RestaurantDataService.getProfile(queryString)
      .then((response) => {
        let info = response.data.userInfo;
        if (!info) {
          info = {};
        }

        for (let i = 0; i < ids.length; i++) {
          if (!(ids[i] in info)) {
            info[ids[i]] = [data[ids[i]][0], data[ids[i]][1], "", "", false];
          }
        }
        setUserInfo(info);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Button
        sx={{
          fontStyle: { color: "black" },
          "&:hover": {
           fontStyle: { color: "black" },
         },
           width:115,
           height:35,
           textTransform: "none",
           fontSize: 14,
           justifyContent: "flex-start",
         }}
        onClick={handleClickOpen}
      >
        🔥 Trending
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 10,  },
        }}
      >
        
        
        <DialogContent sx={{paddingTop:1}}>
                <Typography variant="h5"fontSize={18} sx={{marginTop:2,marginBottom:2}}>Top Players</Typography>
                {topPlayers.map((player, index) => {
                  let emoji = medals[index];
                  return (
                    <div  key={player._id}>
                      {userInfo[player._id] && (
                        <Grid container>
                          <Grid item xs={1.5}>
                            <p style={{ fontSize: 30 }}>{emoji}</p>
                          </Grid>
                          <Grid item xs={1.25}>
                            <Box
                              component="img"
                              sx={{
                                height: 40,
                                width: 40,
                                borderRadius: 50,
                              }}
                              src={userInfo[player._id][1]}
                            />
                          </Grid>
                          <Grid item xs={7.75} sx={{ padding: 1.75 }}>
                            <Typography fontSize={14}>{userInfo[player._id][0]}</Typography>
                          </Grid>
                          <Grid item xs={1} sx={{ paddingTop: 1 }}>
                            <PlayerProfileM
                              email={player.email}
                              userInfo={userInfo[player._id]}
                              id={player._id}
                              login_user={user}
                              //closeWindows={handleCloseAll}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  );
                })}
             
            
             <Typography variant="h5" fontSize={18} sx={{marginBottom:2}}>Top Games</Typography>
            {topGames.map((game, index) => {
                  let emoji = medals[index];
                  return (
                    <div style={{ marginBottom: 2 }} key={game._id}>
                     
                        <Grid container>
                          <Grid item xs={1.25}>
                            <p style={{ fontSize: 30 }}>{emoji}</p>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Box
                              component="img"
                              sx={{
                                height: 40,
                                width: 80,
                                borderRadius: 2.5,
                              }}
                              src={game.image}
                            />
                          </Grid>
                          <Grid item xs={6.75} sx={{ paddingLeft: 3.5,paddingTop: game.game.length > 35 ? 1 : 1.75 }}>
                            <Typography variant="body2" fontSize={12}>{game.game}</Typography>
                          </Grid>
                          <Grid item xs={1} sx={{ paddingTop: 1 }}>
                          <IconButton
                            
                            href={"/games/" + game._id}
                          >
                            <SportsEsportsIcon />
                          </IconButton>
                          </Grid>
                        </Grid>
                      
                    </div>
                  );
                })}
            
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrendingM;
