import React, { useState } from "react";
import RestaurantDataService from "../services/restaurant";
import { Dialog } from "@mui/material";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { DialogContent } from "@mui/material";
import { Typography } from "@mui/material";
import { DialogActions } from "@mui/material";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ImageUploading from "react-images-uploading";
import FollowList from "./follow-list";
import EmailIcon from "@mui/icons-material/Email";
import { MenuItem } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GamesIcon from "@mui/icons-material/Games";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Checkbox } from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";

const Profile = ({ user, setAnchorEl }) => {
  const [hasProfile, setHasProfile] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(user.name);
  const [description, setDescription] = useState("");
  const [fGame, setFGame] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [images, setImages] = useState([user.picture]);
  const [error, setError] = useState(undefined);
  const [checked, setChecked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [follow, setFollow] = useState({
    user_id: "",
    following: {},
    followedBy: {},
  });

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const getProfile = (user_id) => {
    RestaurantDataService.getProfile(user_id)
      .then((response) => {
        if (response.data.profile.length > 0) {
          setHasProfile(true);
          const profile = response.data.profile[0];
          setName(profile.name);
          setDescription(profile.description);
          setFGame(profile.fGame);
          setChecked(profile.showEmail);
          setImages([profile.user_pic]);
        }
        setLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUserLikesReviews = (user_id) => {
    RestaurantDataService.getUserLikesReviews(user_id)
      .then((response) => {
        if (response.data.likes.length > 0) {
          setLikes(response.data.likes[0].count);
        }
        setReviewCount(response.data.review_count);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getFollow = (user_id) => {
    RestaurantDataService.getFollow(user_id)
      .then((response) => {
        if (response.data.follow.length > 0) {
          setFollow(response.data.follow[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages([imageList[0].data_url]);
  };

  const refresh = () => {
    setOpen(false);
    document.location.reload();
  };

  const saveProfile = () => {
    if (name === "") {
      setError(name === "");
      return;
    }

    var data = {
      name: name,
      user_id: user.sub,
      description: description,
      user_pic: images[0],
      fGame: fGame,
      showEmail: checked,
    };

    if (hasProfile) {
      RestaurantDataService.updateProfile(data)
        .then((response) => {
          refresh();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      RestaurantDataService.createProfile(data)
        .then((response) => {
          refresh();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleClickOpen = () => {
    getFollow(user.sub);
    getUserLikesReviews(user.sub);
    getProfile(user.sub);
    setOpen(true);
  };


  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setError(undefined);
  };

  const handleNameChange = (event) => {
    if (event.target.value !== "") {
      setError(undefined);
    }
    setName(event.target.value);
  };

  const handleDesChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFGameChange = (event) => {
    setFGame(event.target.value);
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        <AccountBoxIcon fontSize="small" sx={{ marginRight: 1 }} />
        Profile
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 10, width: 400,height:455 },
        }}
      >
        <DialogContent>
          <div>
            <Grid container>
              <Grid item xs={3.25}>
                {loaded &&
                  images.map((image, index) => (
                    <ImageUploading
                      value={images}
                      onChange={onChange}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, dragProps }) => (
                        <div style={{ marginLeft: 5 }}>
                          <Button
                            onClick={onImageUpload}
                            {...dragProps}
                            variant="contained"
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: 50,
                              boxShadow: 5,
                              "&:hover": {
                                boxShadow: 10,
                              },
                            }}
                          >
                            <img
                              src={image}
                              alt="profile"
                              style={{
                                width: 70,
                                height: 70,
                                borderRadius: 50,
                              }}
                            />
                          </Button>
                        </div>
                      )}
                    </ImageUploading>
                  ))}

                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <FollowList getFollow={getFollow} followed={false} follow={follow.following} user={user} closeParent={handleClose}/>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: 4.5 }}
                    fontSize={14}
                    component="div"
                  >
                    {Object.keys(follow.following).length}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                   <FollowList getFollow={getFollow} followed={true} follow={follow.followedBy} user={user} closeParent={handleClose} />
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: 4.5 }}
                    fontSize={14}
                    component="div"
                  >
                    {Object.keys(follow.followedBy).length}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Typography
                    sx={{ marginLeft: 1.5, fontWeight: "bold" }}
                    variant="body1"
                    fontSize={14}
                    component="div"
                  >
                    Reviews
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: 4.5 }}
                    fontSize={14}
                    component="div"
                  >
                    {reviewCount}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 10,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        color="error"
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                      />
                    }
                    label={
                      <Typography variant="body1" fontSize={16}>
                        {likes}
                      </Typography>
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={1.5}>
                    <AccountCircle
                      sx={{
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                        marginTop: 2.5,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10.5}>
                    <TextField
                      id="input-with-sx"
                      label="Name"
                      variant="standard"
                      value={loaded ? name : ""}
                      onChange={handleNameChange}
                      helperText={error && "Name cannot be empty!"}
                      error={error}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 1.5 }}>
                  <Grid item xs={1.5}>
                    <LightbulbIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                  </Grid>
                  <Grid item xs={10.5}>
                    <TextField
                      id="filled-multiline-static"
                      label="Description"
                      value={description}
                      onChange={handleDesChange}
                      multiline
                      rows={5}
                      fullWidth
                      variant="filled"
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 1 }}>
                  <Grid item xs={1.5}>
                    <GamesIcon
                      sx={{
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                        marginTop: 2.5,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10.5}>
                    <TextField
                      id="input-with-sx"
                      label="Favorite Game"
                      variant="standard"
                      value={fGame}
                      onChange={handleFGameChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sx={{ marginTop: 1 }}>
                    <EmailIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography fontSize={14} sx={{ marginTop: 1.6 }}>
                      {user.email}
                    </Typography>
                  </Grid>
                  <div style={{ marginLeft: 5,marginTop:5 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={checked}
                          onChange={handleCheckChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={
                        <Typography variant="body2">Show Email</Typography>
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            Close
          </Button>
          <Button
            onClick={saveProfile}
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
