import React, { useState, useEffect } from "react";
import RestaurantDataService from "../services/restaurant";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import RecommendIcon from '@mui/icons-material/Recommend';
import Footer from "./footer";
import Pick from "./images/pick.jpeg"

import "./style.css"

import { CardActionArea } from "@mui/material";

const EditorsPicks = (props) => {
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    find(1,"pick");
  }, []);


  const find = (query, by) => {
    RestaurantDataService.find(query, by)
      .then((response) => {
        setRestaurants(response.data.restaurants);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  return (
    <div>
        <div style={{marginTop:60}}>
        <img src={Pick} alt="poster" className="tga"></img>
        </div>
        <Card sx={{boxShadow:"0"}}>
      <div
        className="row"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        {restaurants.map((restaurant) => {
          var g = [];
          for (let i = 0; i < restaurant.genres.length; i++) {
            g.push(restaurant.genres[i].name);
          }
          g = g.join(', ');
          var p = [];
          for (let i = 0; i < restaurant.platforms.length; i++) {
            p.push(restaurant.platforms[i].platform.name);
          }
          p = p.join(', ');
          return (
            <Card
              key={restaurant._id}
              sx={{
                ":hover": { boxShadow: 15 },
                width: 370,
                height:390,
                marginBottom: 2.5,
                marginTop: 2.5,
                marginLeft: 2,
                marginRight: 2,
                padding: 0,
                borderRadius: 4,
                boxShadow: 5,
              }}
            >
              <CardActionArea target="_blank" href={"/games/" + restaurant._id}>
                <CardMedia
                  component="img"
                  height="210"
                  image={restaurant.background_image}
                /><Grid item xs={12}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {restaurant.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Released Date: </strong>
                    {restaurant.released}
                    <br />
                    <strong>Genre(s): </strong>
                    {g}
                    <br />
                    <strong>Platform(s): </strong>
                    {p}
                    <br /><br /><br /><br />
                  </Typography>
                </CardContent>
                </Grid>
              </CardActionArea>
            </Card>
          );
        })}

        <Divider>
          <RecommendIcon/>
        </Divider>
      </div><Footer/></Card>
    </div>
  );
};

export default EditorsPicks;
